html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body * {
    box-sizing: border-box;
}

#root {
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: 600;
}

h1 { font-size: 30px; }
h2 { font-size: 24px; }
h3 { font-size: 21px; }

p {
    line-height: 1.5;
    margin: 0 0 16px 0;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

li { list-style-type: none; }

input,
select,
textarea,
button {
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    font-size: 1rem;
}

button:focus,
input:focus,
textarea:focus,
select:focus {
    outline: 0;
}

textarea {
    line-height: 1.5;
}

button {
    cursor: pointer;
}
